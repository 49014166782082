/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import Image from "next/image";

interface Props {
  property1: "two" | "default";
  className: string;
  frameClassName: string;
  image?: string;
  imageClassName?: string;
  img?: string;
}

export const Firma = ({
  property1,
  className,
  frameClassName,
  image = "https://c.animaapp.com/jUK70hSM/img/image-26@2x.png",
  imageClassName,
  img = "https://c.animaapp.com/jUK70hSM/img/image-25@2x.png",
}: Props): JSX.Element => {
  return (
    <div className={`flex flex-col items-center gap-2.5 justify-center relative ${className}`}>
      {property1 === "default" && (
        <div
          className={`flex flex-col items-start gap-2.5 relative self-stretch w-full flex-[0_0_auto] ${frameClassName}`}
        >
          {img && (
            <Image className={`relative object-contain ${imageClassName}`} alt="firma" src={img} width={50} height={50} />
          )}
        </div>
      )}

      {property1 === "two" && image &&(
        <Image className={`relative object-contain ${frameClassName}`} alt="firma" src={image} width={50} height={50} />
      )}
    </div>
  );
};
