"use client";

import React from "react";
import { Cell } from "./Cell";
import { Firma } from "./Firma";
import { Row } from "./Row";
import Image from "next/image";
import Script from "next/script";
import { IOperation } from "@umahealth/entities";
import { Checkboxes } from "./Checkboxes/Checkboxes";

export const OperationReportPdf = ({
  operation,
}: {
  operation?: IOperation;
}) => {
  const medications = (operation?.questions?.observations_exam?.medicines || [])
    .map(
      ({ dose, last_taken_date, last_taken_time, medication }) =>
        `${medication} ${dose} | ${new Date(last_taken_date).toLocaleDateString("en-GB", { day: "2-digit", month: "2-digit", year: "2-digit" })} - ${last_taken_time} hs`
    )
    .join(`\n`);

  const breathalyzerFirstResult =
    operation?.questions?.substance_exam?.breathalyzer?.type === "Cualitativo"
      ? operation?.questions?.substance_exam?.breathalyzer_result
      : Number(operation?.questions?.substance_exam?.breathalyzer_result) > 0
        ? "Positivo"
        : "Negativo";

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <title></title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <style>
          {`@import url(https://fonts.googleapis.com/css?family=Encode+Sans:600|Roboto:500,var(--roboto-body-11-font-weight),400|Inter:400,600);`}
        </style>
        <style>
          {`@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css);
          * {
            -webkit-font-smoothing: antialiased;
            box-sizing: border-box;
          }
          html,
          body {
            margin: 0px;
            height: 100%;
          }
          /* a blue color as a generic focus style */
          button:focus-visible {
            outline: 2px solid #4a90e2 !important;
            outline: -webkit-focus-ring-color auto 5px !important;
          }
          a {
            text-decoration: none;
          }`}
        </style>
      </head>
      <body data-collection-1-mode="desktop">
        <div id="app">
          <div className="flex flex-col w-full items-start gap-4 p-4 relative bg-white">
            <div className="flex flex-col items-start gap-[7px] relative self-stretch w-full flex-[0_0_auto]">
              <div className="flex items-center justify-between relative self-stretch w-full flex-[0_0_auto]">
                <Image
                  className=""
                  alt="Layer"
                  src="https://c.animaapp.com/jUK70hSM/img/layer1@2x.png"
                  width={100}
                  height={48}
                />
                <p className="relative w-[248px] mt-[-1.00px] [font-family:'Encode_Sans',Helvetica] font-semibold text-neutral-900 text-[10px] tracking-[0] leading-[12.0px]">
                  Protocolo de Evaluación en CRPC y de Evaluación Aleatoria de
                  Personal Operativo
                </p>
              </div>
              <hr className="w-[100%]"></hr>
            </div>
            <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
              <div className="inline-flex items-start gap-2 relative flex-[0_0_auto]">
                <div className="relative w-fit mt-[-1.00px] [font-family:'Encode_Sans',Helvetica] font-semibold text-neutral-900 text-xs tracking-[0] leading-[14.4px] whitespace-nowrap">
                  Datos de la Evaluación
                </div>
              </div>
              <div className="flex flex-col items-start p-2 relative self-stretch w-full flex-[0_0_auto] bg-neutral-50 rounded border border-solid border-neutral-300">
                <div className="flex justify-center gap-1 self-stretch w-full flex-[0_0_auto] items-center relative">
                  <div className="flex items-end justify-center relative flex-1 grow">
                    <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                      <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Lugar:
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col h-[21px] items-start px-0 py-1 relative flex-1 grow">
                      <div className="justify-center p-0.5 flex-1 self-stretch w-full grow flex items-center gap-1 relative">
                        <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                          <div className="relative w-fit mt-[-1.00px] mb-0.5 font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                            {operation ? operation?.base?.name : ""}
                          </div>
                        </div>
                      </div>
                      <hr className="relative self-stretch w-[100%]" />
                    </div>
                  </div>
                  <div className="inline-flex items-end justify-center relative flex-[0_0_auto]">
                    <div className="w-[41px] p-1 flex items-center gap-1 relative">
                      <div className="inline-flex items-start gap-2.5 relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Fecha:
                        </div>
                      </div>
                    </div>
                    <div className="inline-flex items-end justify-center gap-0.5 px-0 py-1 relative flex-[0_0_auto]">
                      <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? new Date(operation?.dt_start)
                                .getDate()
                                .toString()
                                .padStart(2, "0")
                            : ""}
                        </div>
                        <Image
                          className="relative w-[27px] h-px mb-[-0.75px]"
                          alt="Vector"
                          src="https://c.animaapp.com/jUK70hSM/img/vector-653-6.svg"
                          width={27}
                          height={1}
                        />
                      </div>
                      <Image
                        className="relative w-[2.5px] h-[13.03px] mt-[-0.02px] mb-[-0.02px]"
                        alt="Vector"
                        src="https://c.animaapp.com/jUK70hSM/img/vector-656.svg"
                        width={2.5}
                        height={13.03}
                      />
                      <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? (new Date(operation?.dt_start).getMonth() + 1)
                                .toString()
                                .padStart(2, "0")
                            : ""}
                        </div>
                        <Image
                          className="relative w-[27px] h-px mb-[-0.75px]"
                          alt="Vector"
                          src="https://c.animaapp.com/jUK70hSM/img/vector-653-6.svg"
                          width={27}
                          height={1}
                        />
                      </div>
                      <Image
                        className="relative w-[2.5px] h-[13.03px] mt-[-0.02px] mb-[-0.02px]"
                        alt="Vector"
                        src="https://c.animaapp.com/jUK70hSM/img/vector-656.svg"
                        width={2.5}
                        height={13.03}
                      />
                      <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? new Date(operation?.dt_start).getFullYear()
                            : ""}
                        </div>
                        <Image
                          className="relative w-[27px] h-px mb-[-0.75px]"
                          alt="Vector"
                          src="https://c.animaapp.com/jUK70hSM/img/vector-653-6.svg"
                          width={27}
                          height={1}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="inline-flex items-end justify-center relative flex-[0_0_auto]">
                    <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                      <div className="inline-flex items-start gap-2.5 relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Hora:
                        </div>
                      </div>
                    </div>
                    <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                      <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? new Date(operation?.dt_start)
                                .getHours()
                                .toString()
                                .padStart(2, "0")
                            : ""}
                        </div>
                        <Image
                          className="relative w-[27px] h-px mb-[-0.75px]"
                          alt="Vector"
                          src="https://c.animaapp.com/jUK70hSM/img/vector-653-6.svg"
                          width={27}
                          height={1}
                        />
                      </div>
                      <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                        :
                      </div>
                    </div>
                    <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                      <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? new Date(operation?.dt_start)
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")
                            : ""}
                        </div>
                        <Image
                          className="relative w-[27px] h-px mb-[-0.75px]"
                          alt="Vector"
                          src="https://c.animaapp.com/jUK70hSM/img/vector-653-6.svg"
                          width={27}
                          height={1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center gap-1 self-stretch w-full flex-[0_0_auto] items-center relative">
                  <div className="flex items-end justify-center relative flex-1 grow">
                    <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                      <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Nombre y Apellido:
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col h-[21px] items-start px-0 py-1 relative flex-1 grow">
                      <div className="justify-center p-0.5 flex-1 self-stretch w-full grow flex items-center gap-1 relative">
                        <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                          <div className="relative w-fit mt-[-1.00px] mb-0.5 font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                            {operation
                              ? `${operation?.user.firstName} ${operation?.user.lastName}`
                              : ""}
                          </div>
                        </div>
                      </div>
                      <hr className="relative self-stretch w-[100%]" />
                    </div>
                  </div>
                  <div className="inline-flex items-end justify-center relative flex-[0_0_auto]">
                    <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                      <div className="inline-flex items-start gap-2.5 relative flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Hora Toma de Servicio:
                        </div>
                      </div>
                    </div>
                    <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                      <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? new Date(operation?.dt_start)
                                .getHours()
                                .toString()
                                .padStart(2, "0")
                            : ""}
                        </div>
                        <Image
                          className="relative w-[27px] h-px mb-[-0.75px]"
                          alt="Vector"
                          src="https://c.animaapp.com/jUK70hSM/img/vector-653-6.svg"
                          width={27}
                          height={1}
                        />
                      </div>
                      <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                        :
                      </div>
                    </div>
                    <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                      <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? new Date(operation?.dt_start)
                                .getMinutes()
                                .toString()
                                .padStart(2, "0")
                            : ""}
                        </div>
                        <Image
                          className="relative w-[27px] h-px mb-[-0.75px]"
                          alt="Vector"
                          src="https://c.animaapp.com/jUK70hSM/img/vector-653-6.svg"
                          width={27}
                          height={1}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-center gap-1 self-stretch w-full flex-[0_0_auto] items-center relative">
                  <div className="flex items-end justify-center relative flex-1 grow">
                    <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                      <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Función:
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col h-[21px] items-start px-0 py-1 relative flex-1 grow">
                      <div className="justify-center p-0.5 flex-1 self-stretch w-full grow flex items-center gap-1 relative">
                        <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                          <div className="relative w-fit mt-[-1.00px] mb-0.5 font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                            {operation ? operation?.user.roles[0].role : ""}
                          </div>
                        </div>
                      </div>
                      <hr className="relative self-stretch w-[100%]" />
                    </div>
                  </div>
                  <div className="flex w-[181px] items-end justify-center relative">
                    <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                      <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                        <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Número de Legajo:
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col h-[21px] items-start px-0 py-1 relative flex-1 grow">
                      <div className="justify-center p-0.5 flex-1 self-stretch w-full grow flex items-center gap-1 relative">
                        <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                          <div className="w-fit mt-[-1.00px] mb-[-3.00px] whitespace-nowrap relative font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] [font-style:var(--roboto-body-11-font-style)]">
                            {operation ? operation?.user.nomina : ""}
                          </div>
                        </div>
                      </div>
                      <Image
                        className="relative self-stretch w-full h-px mb-[-0.75px]"
                        alt="Vector"
                        src="https://c.animaapp.com/jUK70hSM/img/vector-651-3.svg"
                        width={587}
                        height={1}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
              <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                <p className="relative self-stretch mt-[-1.00px] [font-family:'Encode_Sans',Helvetica] font-semibold text-neutral-900 text-xs tracking-[0] leading-[14.4px]">
                  Prueba a completar por Operador
                </p>
              </div>
              <div className="items-center gap-2 self-stretch w-full flex-[0_0_auto] flex relative">
                <div className="flex-col w-[295px] h-[117px] items-start rounded overflow-hidden border border-solid border-neutral-300 flex relative">
                  <div className="flex items-start relative self-stretch w-full flex-[0_0_auto]">
                    <Cell
                      className="!border-neutral-300 !h-[18px] !bg-neutral-100 !w-[91px]"
                      contentClassName="!mb-[-2.00px]"
                      text="Test del Reloj"
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                    <Cell
                      className="!border-neutral-300 !h-[18px] !flex-1 !grow !bg-neutral-100 !w-[unset]"
                      contentClassName="!mb-[-2.00px]"
                      text="1° intento"
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                    <Cell
                      className="!border-neutral-300 !h-[18px] !flex-1 !grow !bg-neutral-100 !w-[unset]"
                      contentClassName="!mb-[-2.00px]"
                      text="2° intento"
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                  </div>
                  <div className="items-start flex-1 grow flex relative self-stretch w-full">
                    <div className="flex flex-col w-[91px] items-start relative self-stretch border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center p-1 relative self-stretch w-full flex-[0_0_auto]">
                        <p className="relative flex-1 mt-[-1.00px] [font-family:'Roboto',Helvetica] font-normal text-neutral-400 text-[9px] tracking-[0] leading-[10.8px]">
                          Dibuje a la derecha un reloj con manecillas con la
                          hora asignada debajo:
                        </p>
                      </div>
                      <div className="flex items-center justify-center relative flex-1 self-stretch w-full grow">
                        <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                          <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              {operation?.questions?.health_exam?.assigned_time
                                ? new Date(
                                    operation?.questions?.health_exam?.assigned_time
                                  ).getHours()
                                : ""}
                            </div>
                            <hr className="w-[30px]"></hr>
                          </div>
                          <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                            :
                          </div>
                        </div>
                        <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]  mt-4 ml-1">
                          <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              {operation?.questions?.health_exam?.assigned_time
                                ? new Date(
                                    operation?.questions?.health_exam?.assigned_time
                                  ).getMinutes()
                                : ""}
                            </div>
                            <hr className="w-[30px]"></hr>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col w-[102px] h-[99px] items-center justify-center relative border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      {operation &&
                      operation?.questions?.health_exam?.clock_test_draw ? (
                        <Image
                          src={
                            operation?.questions?.health_exam?.clock_test_draw
                          }
                          alt="Clock Test Draw"
                          width={500} // Adjust the width as needed
                          height={500} // Adjust the height as needed
                        />
                      ) : (
                        <div className="relative w-fit font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          No aplica
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col items-center justify-center relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      {operation?.questions?.health_exam
                        ?.clock_test_draw_secondAttempt ? (
                        <Image
                          src={
                            operation?.questions?.health_exam
                              ?.clock_test_draw_secondAttempt
                          }
                          alt="Clock Test Draw"
                          width={500} // Adjust the width as needed
                          height={500} // Adjust the height as needed
                        />
                      ) : (
                        <div className="relative w-fit font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          No aplica
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex-col items-start flex-1 self-stretch grow rounded overflow-hidden border border-solid border-neutral-300 flex relative">
                  <div className="h-[18px] items-center flex relative self-stretch w-full">
                    <Cell
                      className="!border-neutral-300 !h-[18px] !justify-center !flex-1 !grow !bg-neutral-100 !w-[unset]"
                      contentClassName="!mt-[-1.00px] !mb-[-1.00px]"
                      text="Tiempo y Espacio"
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                  </div>
                  <div className="items-start flex-1 grow flex relative self-stretch w-full">
                    <div className="flex flex-col w-16 h-6 items-start relative border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        <div className="relative w-fit mt-[-5.50px] mb-[-3.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Día:
                        </div>
                      </div>
                    </div>
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !flex-1 !grow !bg-[unset] !w-[unset]"
                      text={
                        operation?.questions?.observations_exam?.oriented ===
                        null
                          ? "No aplica"
                          : operation?.questions?.observations_exam
                                ?.oriented === true
                            ? "Respondió correctamente"
                            : "No respondió correctamente"
                      }
                      textClassName="!text-neutral-900"
                      type="default"
                    />
                  </div>
                  <div className="items-start flex-1 grow flex relative self-stretch w-full">
                    <div className="flex flex-col w-16 h-6 items-start relative border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="inline-flex items-center px-3 py-2.5 relative flex-1 grow">
                        <div className="relative w-fit mt-[-5.50px] mb-[-3.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Mes:
                        </div>
                      </div>
                    </div>
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !flex-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!text-[10px]"
                      text={
                        operation?.questions?.observations_exam?.oriented ===
                        null
                          ? "No aplica"
                          : operation?.questions?.observations_exam
                                ?.oriented === true
                            ? "Respondió correctamente"
                            : "No respondió correctamente"
                      }
                      textClassName="!text-neutral-900"
                      type="default"
                    />
                  </div>
                  <div className="items-start flex-1 grow flex relative self-stretch w-full">
                    <div className="flex flex-col w-16 h-6 items-start relative border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="inline-flex items-center px-3 py-2.5 relative flex-1 grow mr-[-5.00px]">
                        <div className="relative w-fit mt-[-5.50px] mb-[-3.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Estación:
                        </div>
                      </div>
                    </div>
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !flex-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!mb-[-11.25px]"
                      text={
                        operation?.questions?.observations_exam?.oriented ===
                        null
                          ? "No aplica"
                          : operation?.questions?.observations_exam
                                ?.oriented === true
                            ? "Respondió correctamente"
                            : "No respondió correctamente"
                      }
                      textClassName="!text-neutral-900"
                      type="default"
                    />
                  </div>
                  <div className="items-start flex-1 grow flex relative self-stretch w-full">
                    <div className="flex flex-col w-16 h-6 items-start relative border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="inline-flex items-center px-3 py-2.5 relative flex-1 grow">
                        <div className="relative w-fit mt-[-5.50px] mb-[-3.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Año:
                        </div>
                      </div>
                    </div>
                    <Cell
                      className="!border-neutral-300 !flex-1 !grow !bg-[unset] !w-[unset]"
                      text={
                        operation?.questions?.observations_exam?.oriented ===
                        null
                          ? "No aplica"
                          : operation?.questions?.observations_exam
                                ?.oriented === true
                            ? "Respondió correctamente"
                            : "No respondió correctamente"
                      }
                      textClassName="!text-neutral-900"
                      type="default"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
              <div className="flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]">
                <div className="relative self-stretch mt-[-1.00px] [font-family:'Encode_Sans',Helvetica] font-semibold text-neutral-900 text-xs tracking-[0] leading-[14.4px]">
                  Evaluación
                </div>
              </div>
              <div className="flex-col items-start self-stretch w-full flex-[0_0_auto] flex relative">
                <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto] rounded overflow-hidden border border-solid border-neutral-300">
                  <div className="h-[18px] items-start flex relative self-stretch w-full">
                    <div className="inline-flex flex-col items-start relative self-stretch flex-[0_0_auto] bg-neutral-100 border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-start px-3 py-1 relative self-stretch w-full flex-[0_0_auto] mb-[-2.00px]">
                        <p className="w-fit mt-[-1.00px] font-normal text-neutral-900 text-[10px] leading-[12.0px] whitespace-nowrap relative [font-family:'Roboto',Helvetica] tracking-[0]">
                          <span className="font-medium">Examen de Salud. </span>
                          <span className="[font-family:'Roboto',Helvetica] font-normal text-[#00151d] text-[10px] tracking-[0] leading-[12.0px]">
                            Marque y complete lo que corresponda.
                          </span>
                        </p>
                      </div>
                    </div>
                    <Cell
                      className="!self-stretch !border-neutral-300 !flex-1 !border-l-[unset] ![border-left-style:unset] !grow !bg-neutral-100 !w-[unset]"
                      contentClassName="!mb-[-2.00px]"
                      text=""
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-normal ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                  </div>
                  <div className="h-5 items-start flex relative self-stretch w-full">
                    <div className="flex flex-col w-[266px] items-start relative self-stretch border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        <p className="relative w-fit mt-[-7.50px] mb-[-5.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          ¿Tiene alteración de la marcha?
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center justify-center relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center justify-center px-3 py-2.5 relative flex-1 self-stretch grow">
                        {operation?.questions?.observations_exam
                          ?.gait_alteration === true ? (
                          <Checkboxes
                            containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                            iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                            stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                            stateProp="enabled"
                            type="selected"
                            typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                          />
                        ) : (
                          <Checkboxes
                            containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                            stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                            stateProp="enabled"
                            type="unselected"
                            typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                          />
                        )}
                        <div className="flex flex-col w-2.5 items-center justify-center gap-2.5 relative mt-[-5.00px] mb-[-5.00px]">
                          <div className="relative self-stretch mt-[-1.00px] [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-1 tracking-[0] leading-[9.6px]">
                            SÍ
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center justify-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        {operation?.questions?.observations_exam
                          ?.gait_alteration === false ? (
                          <Checkboxes
                            containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                            iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                            stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                            stateProp="enabled"
                            type="selected"
                            typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                          />
                        ) : (
                          <Checkboxes
                            containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                            stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                            stateProp="enabled"
                            type="unselected"
                            typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                          />
                        )}
                        <div className="mt-[-6.00px] mb-[-4.00px] relative w-fit [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-1 tracking-[0] leading-[9.6px] whitespace-nowrap">
                          NO
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-5 items-start flex relative self-stretch w-full">
                    <div className="flex flex-col w-[266px] items-start relative self-stretch border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        <div className="relative w-fit mt-[-7.50px] mb-[-5.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          ¿Toma medicación?
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center justify-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        {operation?.questions?.observations_exam
                          ?.takes_medication === true ? (
                          <Checkboxes
                            containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                            iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                            stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                            stateProp="enabled"
                            type="selected"
                            typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                          />
                        ) : (
                          <Checkboxes
                            containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                            stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                            stateProp="enabled"
                            type="unselected"
                            typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                          />
                        )}
                        <div className="w-fit mt-[-6.00px] mb-[-4.00px] whitespace-nowrap relative [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-1 tracking-[0] leading-[9.6px]">
                          SÍ
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center justify-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        {operation?.questions?.observations_exam
                          ?.takes_medication === false ? (
                          <Checkboxes
                            containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                            iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                            stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                            stateProp="enabled"
                            type="selected"
                            typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                          />
                        ) : (
                          <Checkboxes
                            containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                            stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                            stateProp="enabled"
                            type="unselected"
                            typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                          />
                        )}
                        <div className="mt-[-6.00px] mb-[-4.00px] relative w-fit [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-1 tracking-[0] leading-[9.6px] whitespace-nowrap">
                          NO
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="items-start flex-[0_0_auto] flex relative self-stretch w-full">
                    <div className="flex flex-col w-[266px] items-start relative border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="inline-flex items-center px-3 py-2.5 relative flex-[0_0_auto]">
                        <p className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] [font-style:var(--roboto-body-11-font-style)]">
                          Si responde sí a pregunta 2, detalle medicación,
                          <br /> dosis y fecha y hora de última toma.
                        </p>
                      </div>
                    </div>
                    <Cell
                      className="!border-neutral-300 !h-[46px] !flex-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!mb-[unset]"
                      text={medications || "No aplica"}
                      textClassName="!text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                  </div>
                  <div className="h-5 items-start flex relative self-stretch w-full">
                    <div className="flex flex-col w-[266px] items-start relative self-stretch border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        <p className="relative w-fit mt-[-7.50px] mb-[-5.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Frecuencia Cardíaca (valor normal 50-100 lpm)
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-start justify-center relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        <div className="relative w-fit mt-[-7.50px] mb-[-5.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          1° toma:
                        </div>
                        <div className="flex-1 mt-[-7.50px] mb-[-5.50px] relative font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? operation?.questions?.health_exam?.heart_rate
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-start relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        <div className="relative w-fit mt-[-7.50px] mb-[-5.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          2° toma:
                        </div>
                        <div className="relative flex-1 mt-[-7.50px] mb-[-5.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? operation?.questions?.health_exam
                                ?.heart_rate_secondAttemp || "No aplica"
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="h-5 items-start flex relative self-stretch w-full">
                    <div className="flex flex-col w-[266px] items-start relative self-stretch border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        <div className="relative w-fit mt-[-7.50px] mb-[-5.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Oximetría (valor normal&gt;90%)
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-center relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="absolute w-[281px] h-5 top-0 left-0" />
                      <div className="flex items-center justify-center gap-2.5 px-4 py-0 relative flex-1 self-stretch w-full grow">
                        <div className="flex-1 self-stretch mt-[-1.00px] relative font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] [font-style:var(--roboto-body-11-font-style)]">
                          {operation
                            ? operation?.questions?.health_exam?.oximetry ||
                              "No aplica"
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
                <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto] rounded overflow-hidden border border-solid border-neutral-300">
                  <Row
                    cellContentClassName="!mt-[-1.00px] !mb-[-1.00px]"
                    cellContentClassNameOverride="!mb-[-2.00px]"
                    cellContentWrapperClassName="!border-neutral-300 !h-6 !mb-[-6.00px] !w-[unset] !flex-1 !grow !bg-neutral-100" //5
                    cellContentWrapperClassNameOverride="!border-neutral-300 !h-6 !mb-[-6.00px] !bg-neutral-100  !flex-1 !grow !w-[10%]" //6
                    cellDivClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !text-center !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                    cellDivClassNameOverride="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                    cellText="Alcoholemia (normal 0%)"
                    cellText1="¿Se realiza?"
                    cellText2="Resultado"
                    cellText3="Etilómetro"
                    cellText4="N° Serie"
                    cellText5="N° Registro"
                    cellTextClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                    cellTextClassName1="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                    cellTextClassName2="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                    cellTextClassNameOverride="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                    cellTypeDefaultClassName="!border-neutral-300 !h-6 !mb-[-6.00px] !bg-neutral-100 !w-[25%]" //1
                    cellTypeDefaultClassNameOverride="!self-stretch !border-neutral-300 !justify-center  !items-center !w-[15%] !bg-neutral-100" //2
                    cellTypeHeaderClassName="!self-stretch !border-neutral-300 !w-[15%] !bg-neutral-100" //3
                    cellTypeHeaderClassNameOverride="!border-neutral-300 !h-6 !mb-[-6.00px] !bg-neutral-100 !w-[20%]" //4
                    className="!self-stretch !h-[18px] !bg-[unset] !w-full"
                  />
                  <div className="h-5 items-start flex relative self-stretch w-full">
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !bg-[unset] !w-[25%]"
                      contentClassName="!mb-[unset] !items-center"
                      text="Primera prueba"
                      textClassName="!mt-[-7.50px] !text-neutral-600 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !mb-[-5.50px] ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !w-[15%] !bg-[unset]"
                      contentClassName="!mb-[unset] !px-3 !py-0.5 "
                      text={
                        operation?.questions?.substance_exam?.breathalyzer_used
                          ? "Si"
                          : "No"
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !w-[15%] !p-1 !bg-[unset]"
                      contentClassName="!mb-[unset] !px-3 !py-0.5"
                      text={String(
                        operation
                          ? operation?.questions?.substance_exam?.breathalyzer
                            ? operation?.questions?.substance_exam?.breathalyzer
                                ?.type === "Cualitativo"
                              ? operation?.questions?.substance_exam
                                  ?.breathalyzer_result
                              : Number(
                                    operation?.questions?.substance_exam
                                      ?.breathalyzer_result
                                  ) > 0
                                ? "Positivo"
                                : "Negativo"
                            : "No aplica"
                          : ""
                      )}
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !bg-[unset] !w-[20%]"
                      contentClassName="!mb-[unset] !flex-1 !p-1 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam?.breathalyzer
                              ?.brand || "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !flex-1 !p-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!mb-[unset] !flex-1 !px-0 !py-0.5 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam?.breathalyzer
                              ?.serial || "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !flex-1 !p-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!mb-[unset] !flex-1 !px-0 !py-0.5 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam?.breathalyzer
                              ?.barcode || "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                  </div>
                  <div className="items-start flex-[0_0_auto] flex relative self-stretch w-full">
                    <Cell
                      className="!border-neutral-300 !h-5 !bg-[unset] !w-[25%]"
                      contentClassName="!mb-[unset] !flex-1 !items-center !grow"
                      text="Segunda prueba"
                      textClassName="!mt-[-7.50px] !text-neutral-600 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !mb-[-5.50px] ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!border-neutral-300 !h-5 !p-1 !bg-[unset] !w-[15%]"
                      contentClassName="!mb-[unset]  !px-3 !py-0.5"
                      text={
                        //primer etilometro resultado
                        breathalyzerFirstResult === "Positivo"
                          ? operation?.questions?.substance_exam
                              ?.breathalyzer_used_second_attempt
                            ? "Si"
                            : "Se niega"
                          : "No aplica"
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!border-neutral-300 !h-5 !p-1  !bg-[unset] !w-[15%]"
                      contentClassName="!mb-[unset] !px-3 !py-0.5"
                      text={
                        breathalyzerFirstResult === "Positivo"
                          ? String(
                              operation?.questions?.substance_exam
                                ?.breathalyzer_second_attempt?.type ===
                                "Cualitativo"
                                ? operation?.questions?.substance_exam
                                    ?.breathalyzer_result_second_attempt || "-"
                                : Number(
                                      operation?.questions?.substance_exam
                                        ?.breathalyzer_result_second_attempt
                                    ) > 0
                                  ? "Positivo"
                                  : "Negativo"
                            )
                          : "No aplica"
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!border-neutral-300 !h-5 !bg-[unset] !w-[20%]"
                      contentClassName="!mb-[unset] !p-1"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.breathalyzer_second_attempt?.brand ||
                            "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!border-neutral-300 !h-5 !flex-1 !p-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!mb-[unset] !flex-1 !px-0 !py-0.5 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.breathalyzer_second_attempt?.serial ||
                            "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!border-neutral-300 !h-5 !flex-1 !p-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!mb-[unset] !flex-1 !px-0 !py-0.5 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.breathalyzer_second_attempt?.barcode ||
                            "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-col items-start self-stretch w-full flex-[0_0_auto] flex relative">
                <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto] rounded overflow-hidden border border-solid border-neutral-300">
                  <div className="items-start flex-[0_0_auto] flex relative self-stretch w-full">
                    <div className="inline-flex flex-col h-[18px] items-start relative flex-[0_0_auto] bg-neutral-100 border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-start px-3 py-1 relative self-stretch w-full flex-[0_0_auto] mb-[-2.00px]">
                        <p className="relative w-fit mt-[-1.00px] [font-family:'Roboto',Helvetica] font-medium text-neutral-900 text-[10px] tracking-[0] leading-[12.0px] whitespace-nowrap">
                          Prueba de Sustancias Antígenas (0%)
                        </p>
                      </div>
                    </div>
                    <Cell
                      className="!border-neutral-300 !h-[18px] !bg-neutral-100 !w-[76px]"
                      contentClassName="!mb-[-2.00px]"
                      text="¿Se realiza?"
                      textClassName="!mr-[-2.00px] !text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                    <Cell
                      className="!border-neutral-300 !h-[18px] !bg-neutral-100 !w-20"
                      contentClassName="![align-self:unset] !mb-[-2.00px] !inline-flex !w-[unset]"
                      text="Resultado"
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                    <Cell
                      className="!border-neutral-300 !h-[18px] !flex-1 !grow !bg-neutral-100 !w-[unset]"
                      contentClassName="!mb-[-2.00px]"
                      text="Detalle"
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                  </div>
                  <div className="h-5 items-start flex relative self-stretch w-full">
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !bg-[unset] !w-48"
                      contentClassName="![align-self:unset] !mb-[unset] !flex-1 !inline-flex !items-center !grow !w-[unset]"
                      text="Primera (hisopado bucal)"
                      textClassName="!mt-[-7.50px] !text-neutral-600 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !mb-[-5.50px] !flex-[unset] !whitespace-nowrap ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)] !w-fit"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !p-1 !bg-[unset] !w-[76px]"
                      contentClassName="!mb-[unset] !flex-1 !px-3 !py-0.5 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.perform_substance_test
                            ? "SÍ"
                            : "NO"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !p-1 !bg-[unset] !w-20"
                      contentClassName="!mb-[unset] !flex-1 !px-3 !py-0.5 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.substance_test_result || "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !flex-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!mb-[unset] !flex-1 !p-1 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.positive_substances || "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                  </div>
                  <div className="h-5 items-start flex relative self-stretch w-full">
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !bg-[unset] !w-48"
                      contentClassName="![align-self:unset] !mb-[unset] !flex-1 !inline-flex !items-center !grow !w-[unset]"
                      text="Segunda (hisopado bucal)"
                      textClassName="!mt-[-7.50px] !text-neutral-600 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !mb-[-5.50px] !flex-[unset] !whitespace-nowrap ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)] !w-fit"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !p-1 !bg-[unset] !w-[76px]"
                      contentClassName="!mb-[unset] !flex-1 !px-3 !py-0.5 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.perform_substance_test_second_attempt
                            ? "SÍ"
                            : operation?.questions?.substance_exam
                                  ?.perform_substance_test_second_attempt ===
                                false
                              ? "NO"
                              : "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !p-1 !bg-[unset] !w-20"
                      contentClassName="!mb-[unset] !flex-1 !px-3 !py-0.5 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.substance_test_result_second_attempt ||
                            "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                    <Cell
                      className="!self-stretch !border-neutral-300 !h-[unset] !flex-1 !grow !bg-[unset] !w-[unset]"
                      contentClassName="!mb-[unset] !flex-1 !p-1 !grow"
                      text={
                        operation
                          ? operation?.questions?.substance_exam
                              ?.positive_substances_second_attempt ||
                            "No aplica"
                          : ""
                      }
                      textClassName="!self-stretch !text-neutral-800 !tracking-[var(--roboto-body-11-letter-spacing)] !text-[length:var(--roboto-body-11-font-size)] !whitespace-nowrap !text-center ![font-style:var(--roboto-body-11-font-style)] !font-[number:var(--roboto-body-11-font-weight)] !font-roboto-body-11 !leading-[var(--roboto-body-11-line-height)]"
                      type="default"
                    />
                  </div>
                </div>
              </div>
              <div className="flex-col items-start self-stretch w-full flex-[0_0_auto] flex relative">
                <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto] rounded overflow-hidden border border-solid border-neutral-300">
                  <div className="items-start flex-[0_0_auto] flex relative self-stretch w-full">
                    <Cell
                      className="!border-neutral-300 !h-[18px] !bg-neutral-100 !w-[254px]"
                      contentClassName="!mb-[-2.00px]"
                      text="Preguntas finales"
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-medium ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                    <Cell
                      className="!border-neutral-300 !h-[18px] !flex-1 !border-l-[unset] ![border-left-style:unset] !grow !bg-neutral-100 !w-[unset]"
                      contentClassName="!mb-[-2.00px]"
                      text=""
                      textClassName="!text-neutral-900 !text-[10px] !flex-[unset] !whitespace-nowrap !font-normal ![font-family:'Roboto',Helvetica] !leading-[12.0px] !w-fit"
                      type="header"
                    />
                  </div>
                  <div className="h-5 items-start overflow-hidden flex relative self-stretch w-full">
                    <div className="inline-flex flex-col items-start relative self-stretch flex-[0_0_auto] border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-center px-3 py-2.5 relative flex-1 self-stretch w-full grow">
                        <p className="relative w-fit mt-[-7.50px] mb-[-5.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Indicadores y tendencias de comportamiento que impida
                          realizar sus tareas
                        </p>
                      </div>
                    </div>
                    <div className="flex h-6 items-center justify-center relative flex-1 grow mb-[-4.00px] border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      {operation?.questions?.resume?.apto === false ? (
                        <Checkboxes
                          containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                          iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                          stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                          stateProp="enabled"
                          type="selected"
                          typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                        />
                      ) : (
                        <Checkboxes
                          containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                          stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                          stateProp="enabled"
                          type="unselected"
                          typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                        />
                      )}
                      <div className="w-fit whitespace-nowrap relative [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-1 tracking-[0] leading-[9.6px]">
                        SÍ
                      </div>
                    </div>
                    <div className="flex h-6 items-center justify-center relative flex-1 grow mb-[-4.00px] border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      {operation?.questions?.resume?.apto === true ? (
                        <Checkboxes
                          containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                          iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                          stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                          stateProp="enabled"
                          type="selected"
                          typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                        />
                      ) : (
                        <Checkboxes
                          containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                          stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                          stateProp="enabled"
                          type="unselected"
                          typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                        />
                      )}
                      <div className="relative w-fit [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-1 tracking-[0] leading-[9.6px] whitespace-nowrap">
                        NO
                      </div>
                    </div>
                  </div>
                  <div className="h-[35px] items-start flex relative self-stretch w-full">
                    <div className="flex flex-col items-start relative flex-1 self-stretch grow border-t [border-top-style:solid] border-l [border-left-style:solid] border-neutral-300">
                      <div className="flex items-start px-3 py-1 relative flex-1 self-stretch w-full grow">
                        <div className="relative w-fit mt-[-1.00px] mb-[-2.50px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                          Evaluación relevante/ Obervaciones
                        </div>
                      </div>
                      <div className="flex items-center justify-center gap-2.5 px-4 py-0 relative flex-1 self-stretch w-full grow">
                        <p className="relative flex-1 self-stretch mt-[-1.00px] [font-family:'Roboto',Helvetica] font-normal text-neutral-800 text-[11px] ml-1 tracking-[0] leading-[9.6px]">
                          {operation?.questions?.resume?.observations || ""}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex h-[54px] items-end justify-end gap-10 relative self-stretch w-full">
              <div className="flex flex-col items-center justify-end gap-1 relative flex-1 self-stretch grow">
                <Firma
                  className="!h-[30px] !w-[66px]"
                  frameClassName="!self-stretch !h-[unset] ![object-fit:unset] !flex-1 !grow !w-full"
                  image={operation?.questions?.resume?.operator_signature ?? ""}
                  property1="two"
                />
                <Image
                  className="relative w-[118px] h-px object-cover"
                  alt="Vector"
                  src="https://c.animaapp.com/jUK70hSM/img/vector-657.svg"
                  width={118}
                  height={1}
                />
                <div className="font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-400 text-[length:var(--roboto-body-11-font-size)] text-center tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] relative self-stretch [font-style:var(--roboto-body-11-font-style)]">
                  Firma Operador
                </div>
              </div>
              <div className="flex flex-col items-center justify-end gap-1 relative flex-1 self-stretch grow">
                <Firma
                  className="!gap-[5.16px] !flex-[0_0_auto] !w-[62.5px]"
                  frameClassName="!gap-[5.16px]"
                  imageClassName="!h-[28.9px]"
                  img={operation?.questions?.resume?.evaluator_signature ?? ""}
                  property1="default"
                />
                <Image
                  className="relative w-[118px] h-px object-cover"
                  alt="Vector"
                  src="https://c.animaapp.com/jUK70hSM/img/vector-657-1.svg"
                  width={118}
                  height={1}
                />
                <div className="font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-400 text-[length:var(--roboto-body-11-font-size)] text-center tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] relative self-stretch [font-style:var(--roboto-body-11-font-style)]">
                  Firma Evaluador
                </div>
              </div>
              {operation?.doctor_signature && (
                <div className="flex flex-col items-center justify-end gap-1 relative flex-1 self-stretch grow">
                  <Firma
                    className="!gap-[5.16px] !flex-[0_0_auto] !w-[62.5px]"
                    frameClassName="!gap-[5.16px]"
                    imageClassName="!h-[28.9px]"
                    img={operation?.doctor_signature}
                    property1="default"
                  />
                  <Image
                    className="relative w-[118px] h-px object-cover"
                    alt="Vector"
                    src="https://c.animaapp.com/jUK70hSM/img/vector-657-1.svg"
                    width={118}
                    height={1}
                  />
                  <div className="font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-400 text-[length:var(--roboto-body-11-font-size)] text-center tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] relative self-stretch [font-style:var(--roboto-body-11-font-style)]">
                    Firma Médico
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-col items-center justify-end relative self-stretch w-full flex-[0_0_auto]">
              <svg
                width="755"
                height="38"
                viewBox="0 0 555 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.09 6.09L9 9M15 3L6.09 11.91M11.1 11.1L15 15M6.75 4.5C6.75 5.74264 5.74264 6.75 4.5 6.75C3.25736 6.75 2.25 5.74264 2.25 4.5C2.25 3.25736 3.25736 2.25 4.5 2.25C5.74264 2.25 6.75 3.25736 6.75 4.5ZM6.75 13.5C6.75 14.7426 5.74264 15.75 4.5 15.75C3.25736 15.75 2.25 14.7426 2.25 13.5C2.25 12.2574 3.25736 11.25 4.5 11.25C5.74264 11.25 6.75 12.2574 6.75 13.5Z"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 9L555 9"
                  stroke="black"
                  stroke-opacity="0.8"
                  stroke-dasharray="10 5"
                />
              </svg>
              <div className="items-end justify-center gap-1 self-stretch w-full flex-[0_0_auto] flex relative">
                <div className="flex-col items-start p-2 flex-1 grow rounded overflow-hidden border border-solid border-neutral-300 flex relative">
                  <div className="flex w-[100%] items-center justify-evenly relative  mr-[-11.81px] mb-2 mt-2">
                    <Image
                      className="relative grow-0"
                      alt="Frame"
                      src="https://c.animaapp.com/jUK70hSM/img/layer1@2x.png"
                      width={150}
                      height={1}
                    />
                    <div className="flex flex-col items-center justify-center gap-2.5 relative">
                      <div className="relative w-[269.5px] mt-[-1.00px] flex flex-col ml-[-67.44px] mr-[-67.44px] [font-family:'Roboto',Helvetica] font-medium text-neutral-800 text-[14px] text-center tracking-[0] leading-[18.0px]">
                        <span>Talón para trabajador</span>
                        <span className="[font-family:'Roboto',Helvetica] font-normal text-neutral-400 text-[14px] text-center tracking-[0] leading-[18px] whitespace-nowrap">
                          Coordinación CRPC
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
                    <div className="flex w-[270px] items-center relative flex-[0_0_auto] mr-[-12.56px]">
                      <div className="flex w-[114px] items-end justify-center relative">
                        <div className="w-[41px] p-1 flex items-center gap-1 relative">
                          <div className="inline-flex items-start gap-2.5 relative flex-[0_0_auto]">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              Fecha:
                            </div>
                          </div>
                        </div>
                        <div className="flex items-end justify-center gap-0.5 px-0 py-1 relative flex-1 grow">
                          <div className="flex flex-col justify-end flex-1 grow items-center relative">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              {operation
                                ? new Date(operation?.dt_start)
                                    .getDay()
                                    .toString()
                                    .padStart(2, "0")
                                : ""}
                            </div>
                            <Image
                              className="relative self-stretch w-full h-px mb-[-0.75px]"
                              alt="Vector"
                              src="https://c.animaapp.com/jUK70hSM/img/vector-653-9.svg"
                              width={270}
                              height={1}
                            />
                          </div>
                          <Image
                            className="relative w-[2.5px] h-[13.03px] mt-[-0.02px] mb-[-0.02px]"
                            alt="Vector"
                            src="https://c.animaapp.com/jUK70hSM/img/vector-655-1.svg"
                            width={2.5}
                            height={13.03}
                          />
                          <div className="flex flex-col justify-end flex-1 grow items-center relative">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              {operation
                                ? new Date(operation?.dt_start)
                                    .getMonth()
                                    .toString()
                                    .padStart(2, "0")
                                : ""}
                            </div>
                            <Image
                              className="relative self-stretch w-full h-px mb-[-0.75px]"
                              alt="Vector"
                              src="https://c.animaapp.com/jUK70hSM/img/vector-653-10.svg"
                              width={270}
                              height={1}
                            />
                          </div>
                          <Image
                            className="relative w-[2.5px] h-[13.03px] mt-[-0.02px] mb-[-0.02px]"
                            alt="Vector"
                            src="https://c.animaapp.com/jUK70hSM/img/vector-656-1.svg"
                            width={2.5}
                            height={13.03}
                          />
                          <div className="flex flex-col justify-end flex-1 grow items-center relative">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              {operation
                                ? new Date(operation?.dt_start)
                                    .getFullYear()
                                    .toString()
                                    .slice(2)
                                : ""}
                            </div>
                            <Image
                              className="relative self-stretch w-full h-px mb-[-0.75px]"
                              alt="Vector"
                              src="https://c.animaapp.com/jUK70hSM/img/vector-653-11.svg"
                              width={270}
                              height={1}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inline-flex items-end justify-center relative flex-[0_0_auto]">
                        <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                          <div className="inline-flex items-start gap-2.5 relative flex-[0_0_auto]">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              Hora muestra:
                            </div>
                          </div>
                        </div>
                        <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                          <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              {operation
                                ? new Date(operation?.dt_start)
                                    .getHours()
                                    .toString()
                                    .padStart(2, "0")
                                : ""}
                            </div>
                            <Image
                              className="relative w-[27px] h-px mb-[-0.75px]"
                              alt="Vector"
                              src="https://c.animaapp.com/jUK70hSM/img/vector-653-13.svg"
                              width={27}
                              height={1}
                            />
                          </div>
                          <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                            :
                          </div>
                        </div>
                        <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                          <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              {operation
                                ? new Date(operation?.dt_start)
                                    .getMinutes()
                                    .toString()
                                    .padStart(2, "0")
                                : ""}
                            </div>
                            <Image
                              className="relative w-[27px] h-px mb-[-0.75px]"
                              alt="Vector"
                              src="https://c.animaapp.com/jUK70hSM/img/vector-653-13.svg"
                              width={27}
                              height={1}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex h-[21px] items-end justify-center relative self-stretch w-full">
                      <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                        <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                          <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                            Nombre y Apellido:
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col h-[21px] items-start px-0 py-1 relative flex-1 grow">
                        <div className="justify-center p-0.5 flex-1 self-stretch w-full grow flex items-center gap-1 relative">
                          <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                            <div className="relative w-fit mt-[-1.00px] mb-[-3.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              {`${operation?.user.firstName} ${operation?.user.lastName}`}
                            </div>
                          </div>
                        </div>
                        <Image
                          className="relative self-stretch w-full h-px mb-[-0.75px]"
                          alt="Vector"
                          src="https://c.animaapp.com/jUK70hSM/img/vector-651-4.svg"
                          width={587}
                          height={1}
                        />
                      </div>
                    </div>
                    <div className="flex h-[21px] items-end justify-center relative self-stretch w-full">
                      <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                        <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                          <p className="relative w-fit mt-[-1.00px] [font-family:'Roboto',Helvetica] font-normal text-transparent text-[11px] ml-1 tracking-[0] leading-[13.2px] whitespace-nowrap">
                            <span className="text-[#656872] font-roboto-body-11 [font-style:var(--roboto-body-11-font-style)] font-[number:var(--roboto-body-11-font-weight)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] text-[length:var(--roboto-body-11-font-size)]">
                              ¿Tiene apto?{" "}
                            </span>
                            <span className="text-[#9396a0] text-[11px] ml-1 leading-[9.6px]">
                              Marque lo que corresponda:
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center justify-around relative flex-1 self-stretch grow border-0 border-none border-neutral-300">
                        <div className="flex items-center justify-center gap-6 px-3 py-2.5 relative flex-1 self-stretch grow">
                          <div className="inline-flex items-center relative flex-[0_0_auto] mt-[-4.50px] mb-[-4.50px] ml-[-0.78px]">
                            {operation?.apto === true ? (
                              <Checkboxes
                                containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                                iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                                stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                                stateProp="enabled"
                                type="selected"
                                typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                              />
                            ) : (
                              <Checkboxes
                                containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                                stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                                stateProp="enabled"
                                type="unselected"
                                typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                              />
                            )}
                            <div className="flex flex-col w-2.5 items-center justify-center gap-2.5 relative">
                              <div className="self-stretch mt-[-1.00px] relative [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-1 tracking-[0] leading-[9.6px]">
                                SÍ
                              </div>
                            </div>
                          </div>
                          <div className="inline-flex items-center relative flex-[0_0_auto] mt-[-4.50px] mb-[-4.50px] mr-[-0.78px]">
                            {operation?.apto === false ? (
                              <Checkboxes
                                containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                                iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                                stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                                stateProp="enabled"
                                type="selected"
                                typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                              />
                            ) : (
                              <Checkboxes
                                containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                                stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                                stateProp="enabled"
                                type="unselected"
                                typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                              />
                            )}
                            <div className="flex flex-col w-2.5 items-center justify-center gap-2.5 relative">
                              <div className="relative w-fit mt-[-1.00px] [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-2 tracking-[0] leading-[9.6px] whitespace-nowrap">
                                NO
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
										<div className="flex justify-between">
											<div className="flex flex-col items-center justify-end gap-1 relative flex-1 self-stretch grow">
												<Firma
													className="!gap-[5.16px] !flex-[0_0_auto] !w-[62.5px]"
													frameClassName="!gap-[5.16px]"
													imageClassName="!h-[28.9px]"
													img={
														operation?.questions?.resume
															?.evaluator_signature ?? ""
													}
													property1="default"
												/>
												<Image
													className="relative w-[118px] h-px object-cover"
													alt="Vector"
													src="https://c.animaapp.com/jUK70hSM/img/vector-657-1.svg"
													width={118}
													height={1}
												/>
												<div className="font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-400 text-[length:var(--roboto-body-11-font-size)] text-center tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] relative self-stretch [font-style:var(--roboto-body-11-font-style)]">
													Firma Evaluador
												</div>
											</div>
											{operation?.doctor_signature && (
												<div className="flex flex-col items-center justify-end gap-1 relative flex-1 self-stretch grow">
													<Firma
														className="!gap-[5.16px] !flex-[0_0_auto] !w-[62.5px]"
														frameClassName="!gap-[5.16px]"
														imageClassName="!h-[28.9px]"
														img={operation?.doctor_signature}
														property1="default"
													/>
													<Image
														className="relative w-[118px] h-px object-cover"
														alt="Vector"
														src="https://c.animaapp.com/jUK70hSM/img/vector-657-1.svg"
														width={118}
														height={1}
													/>
													<div className="font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-400 text-[length:var(--roboto-body-11-font-size)] text-center tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] relative self-stretch [font-style:var(--roboto-body-11-font-style)]">
														Firma Médico
													</div>
												</div>
											)}
										</div>
                  </div>
                </div>
                <svg
                  width="3"
                  height="153"
                  viewBox="0 0 3 153"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.5625 0L1.43751 153"
                    stroke="black"
                    stroke-opacity="0.8"
                    stroke-dasharray="10 5"
                  />
                </svg>
                <div className="flex-col items-start p-2 flex-1 grow rounded overflow-hidden border border-solid border-neutral-300 flex relative">
                  <div className="flex items-center justify-evenly relative w-full mb-2 mt-2">
                    <Image
                      className="relative"
                      alt="Frame"
                      src="https://c.animaapp.com/jUK70hSM/img/layer1@2x.png"
                      width={150}
                      height={1}
                    />
                    <div className="flex flex-col items-center justify-center gap-2.5 relative flex-1 grow">
                      <div className="relative w-[269.5px] mt-[-1.00px] flex flex-col ml-[-67.44px] mr-[-67.44px] [font-family:'Roboto',Helvetica] font-medium text-neutral-800 text-[14px] text-center tracking-[0] leading-[18.0px]">
                        <span>Talón para Jefe Supervisor</span>
                        <span className="[font-family:'Roboto',Helvetica] font-normal text-neutral-400 text-[14px] text-center tracking-[0] leading-[18px] whitespace-nowrap">
                          Coordinación CRPC
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
                    <div className="flex flex-col items-start relative self-stretch w-full flex-[0_0_auto]">
                      <div className="flex w-[270px] items-center relative flex-[0_0_auto] mr-[-12.56px]">
                        <div className="flex w-[114px] items-end justify-center relative">
                          <div className="w-[41px] p-1 flex items-center gap-1 relative">
                            <div className="inline-flex items-start gap-2.5 relative flex-[0_0_auto]">
                              <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                                Fecha:
                              </div>
                            </div>
                          </div>
                          <div className="flex items-end justify-center gap-0.5 px-0 py-1 relative flex-1 grow">
                            <div className="flex flex-col justify-end flex-1 grow items-center relative">
                              <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                                {operation
                                  ? new Date(operation?.dt_start)
                                      .getDay()
                                      .toString()
                                      .padStart(2, "0")
                                  : ""}
                              </div>
                              <Image
                                className="relative self-stretch w-full h-px mb-[-0.75px]"
                                alt="Vector"
                                src="https://c.animaapp.com/jUK70hSM/img/vector-653-9.svg"
                                width={587}
                                height={1}
                              />
                            </div>
                            <Image
                              className="relative w-[2.5px] h-[13.03px] mt-[-0.02px] mb-[-0.02px]"
                              alt="Vector"
                              src="https://c.animaapp.com/jUK70hSM/img/vector-655-1.svg"
                              width={2.5}
                              height={13.03}
                            />
                            <div className="flex flex-col justify-end flex-1 grow items-center relative">
                              <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                                {operation
                                  ? new Date(operation?.dt_start)
                                      .getMonth()
                                      .toString()
                                      .padStart(2, "0")
                                  : ""}
                              </div>
                              <Image
                                className="relative self-stretch w-full h-px mb-[-0.75px]"
                                alt="Vector"
                                src="https://c.animaapp.com/jUK70hSM/img/vector-653-10.svg"
                                width={587}
                                height={1}
                              />
                            </div>
                            <Image
                              className="relative w-[2.5px] h-[13.03px] mt-[-0.02px] mb-[-0.02px]"
                              alt="Vector"
                              src="https://c.animaapp.com/jUK70hSM/img/vector-656-1.svg"
                              width={2.5}
                              height={13.03}
                            />
                            <div className="flex flex-col justify-end flex-1 grow items-center relative">
                              <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                                {operation
                                  ? new Date(operation?.dt_start)
                                      .getFullYear()
                                      .toString()
                                      .slice(2)
                                  : ""}
                              </div>
                              <Image
                                className="relative self-stretch w-full h-px mb-[-0.75px]"
                                alt="Vector"
                                src="https://c.animaapp.com/jUK70hSM/img/vector-653-11.svg"
                                width={587}
                                height={1}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="inline-flex items-end justify-center relative flex-[0_0_auto]">
                          <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                            <div className="inline-flex items-start gap-2.5 relative flex-[0_0_auto]">
                              <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                                Hora muestra:
                              </div>
                            </div>
                          </div>
                          <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                            <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                              <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                                {operation
                                  ? new Date(operation?.dt_start)
                                      .getHours()
                                      .toString()
                                      .padStart(2, "0")
                                  : ""}
                              </div>
                              <Image
                                className="relative w-[27px] h-px mb-[-0.75px]"
                                alt="Vector"
                                src="https://c.animaapp.com/jUK70hSM/img/vector-653-13.svg"
                                width={27}
                                height={1}
                              />
                            </div>
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              :
                            </div>
                          </div>
                          <div className="inline-flex items-end justify-center gap-1 px-0 py-1 relative flex-[0_0_auto]">
                            <div className="inline-flex flex-col justify-end flex-[0_0_auto] items-center relative">
                              <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                                {operation
                                  ? new Date(operation?.dt_start)
                                      .getMinutes()
                                      .toString()
                                      .padStart(2, "0")
                                  : ""}
                              </div>
                              <Image
                                className="relative w-[27px] h-px mb-[-0.75px]"
                                alt="Vector"
                                src="https://c.animaapp.com/jUK70hSM/img/vector-653-13.svg"
                                width={27}
                                height={1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex h-[21px] items-end justify-center relative self-stretch w-full">
                        <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                          <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                            <div className="relative w-fit mt-[-1.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-600 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                              Nombre y Apellido:
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col h-[21px] items-start px-0 py-1 relative flex-1 grow">
                          <div className="justify-center p-0.5 flex-1 self-stretch w-full grow flex items-center gap-1 relative">
                            <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                              <div className="relative w-fit mt-[-1.00px] mb-[-3.00px] font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-800 text-[length:var(--roboto-body-11-font-size)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] whitespace-nowrap [font-style:var(--roboto-body-11-font-style)]">
                                {operation
                                  ? `${operation?.user.firstName} ${operation?.user.lastName}`
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <Image
                            className="relative self-stretch w-full h-px mb-[-0.75px]"
                            alt="Vector"
                            src="https://c.animaapp.com/jUK70hSM/img/vector-651-5.svg"
                            width={587}
                            height={1}
                          />
                        </div>
                      </div>
                      <div className="flex h-[21px] items-end justify-center relative self-stretch w-full">
                        <div className="inline-flex items-center gap-1 p-1 relative flex-[0_0_auto]">
                          <div className="inline-flex items-start gap-2.5 relative self-stretch flex-[0_0_auto]">
                            <p className="relative w-fit mt-[-1.00px] [font-family:'Roboto',Helvetica] font-normal text-transparent text-[11px] ml-1 tracking-[0] leading-[13.2px] whitespace-nowrap">
                              <span className="text-[#656872] font-roboto-body-11 [font-style:var(--roboto-body-11-font-style)] font-[number:var(--roboto-body-11-font-weight)] tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] text-[length:var(--roboto-body-11-font-size)]">
                                ¿Tiene apto?{" "}
                              </span>
                              <span className="text-[#9396a0] text-[11px] ml-1 leading-[9.6px]">
                                Marque lo que corresponda:
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-around relative flex-1 self-stretch grow border-0 border-none border-neutral-300">
                          <div className="flex items-center justify-center gap-6 px-3 py-2.5 relative flex-1 self-stretch grow">
                            <div className="inline-flex items-center relative flex-[0_0_auto] mt-[-4.50px] mb-[-4.50px] ml-[-0.78px]">
                              {operation?.apto === true ? (
                                <Checkboxes
                                  containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                                  iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                                  stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                                  stateProp="enabled"
                                  type="selected"
                                  typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                                />
                              ) : (
                                <Checkboxes
                                  containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                                  stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                                  stateProp="enabled"
                                  type="unselected"
                                  typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                                />
                              )}
                              <div className="flex flex-col w-2.5 items-center justify-center gap-2.5 relative">
                                <div className="self-stretch mt-[-1.00px] relative [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-1 tracking-[0] leading-[9.6px]">
                                  SÍ
                                </div>
                              </div>
                            </div>
                            <div className="inline-flex items-center relative flex-[0_0_auto] mt-[-4.50px] mb-[-4.50px] mr-[-0.78px]">
                              {operation?.apto === false ? (
                                <Checkboxes
                                  containerClassNameOverride="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !w-[12px]"
                                  iconsCheckSmall5StyleOverrideClassName="!absolute !w-2 !h-2 !top-px !left-px"
                                  stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                                  stateProp="enabled"
                                  type="selected"
                                  typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                                />
                              ) : (
                                <Checkboxes
                                  containerClassName="!h-[10px] !mr-[-1.93px] !mt-[-1.93px] !rounded-[0.64px] !ml-[-1.93px] !mb-[-1.93px] !border-[0.64px] !border-solid !w-[12px]"
                                  stateLayerClassName="!h-[10px] !mr-[-1.29px] !mt-[-1.29px] !rounded-[32.14px] !ml-[-1.29px] !mb-[-1.29px] !p-[3.54px] !w-[12px]"
                                  stateProp="enabled"
                                  type="unselected"
                                  typeSelectedStateClassName="!h-[10px] !mt-[-4.50px] !mb-[-4.50px] !p-[1.29px] !w-[12px]"
                                />
                              )}
                              <div className="flex flex-col w-2.5 items-center justify-center gap-2.5 relative">
                                <div className="relative w-fit mt-[-1.00px]  mr-[-1.00px] [font-family:'Roboto',Helvetica] font-normal text-neutral-600 text-[11px] ml-2 tracking-[0] leading-[9.6px] whitespace-nowrap">
                                  NO
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div className="flex flex-col items-center justify-end gap-1 relative flex-1 self-stretch grow">
                          <Firma
                            className="!gap-[5.16px] !flex-[0_0_auto] !w-[62.5px]"
                            frameClassName="!gap-[5.16px]"
                            imageClassName="!h-[28.9px]"
                            img={
                              operation?.questions?.resume
                                ?.evaluator_signature ?? ""
                            }
                            property1="default"
                          />
                          <Image
                            className="relative w-[118px] h-px object-cover"
                            alt="Vector"
                            src="https://c.animaapp.com/jUK70hSM/img/vector-657-1.svg"
                            width={118}
                            height={1}
                          />
                          <div className="font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-400 text-[length:var(--roboto-body-11-font-size)] text-center tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] relative self-stretch [font-style:var(--roboto-body-11-font-style)]">
                            Firma Evaluador
                          </div>
                        </div>
                        {operation?.doctor_signature && (
                          <div className="flex flex-col items-center justify-end gap-1 relative flex-1 self-stretch grow">
                            <Firma
                              className="!gap-[5.16px] !flex-[0_0_auto] !w-[62.5px]"
                              frameClassName="!gap-[5.16px]"
                              imageClassName="!h-[28.9px]"
                              img={operation?.doctor_signature}
                              property1="default"
                            />
                            <Image
                              className="relative w-[118px] h-px object-cover"
                              alt="Vector"
                              src="https://c.animaapp.com/jUK70hSM/img/vector-657-1.svg"
                              width={118}
                              height={1}
                            />
                            <div className="font-roboto-body-11 font-[number:var(--roboto-body-11-font-weight)] text-neutral-400 text-[length:var(--roboto-body-11-font-size)] text-center tracking-[var(--roboto-body-11-letter-spacing)] leading-[var(--roboto-body-11-line-height)] relative self-stretch [font-style:var(--roboto-body-11-font-style)]">
                              Firma Médico
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Script type="module" src="./src/index.tsx"></Script>
      </body>
    </html>
  );
};
