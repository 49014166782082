/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { useReducer } from "react";
import { IconsCheckSmall5 } from "../icons/IconsCheckSmall5";
import { TypeUnselectedStatePressed } from "../icons/TypeUnselectedStatePressed";

interface Props {
  type: "selected" | "unselected";
  stateProp: "enabled" | "pressed" | "focused" | "hovered" | "disabled";
  typeSelectedStateClassName: string;
  stateLayerClassName: string;
  containerClassName?: string;
  containerClassNameOverride?: string;
  iconsCheckSmall5StyleOverrideClassName?: string;
}

export const Checkboxes = ({
  type,
  stateProp,
  typeSelectedStateClassName,
  stateLayerClassName,
  containerClassName,
  containerClassNameOverride,
  iconsCheckSmall5StyleOverrideClassName,
}: Props): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, {
    type: type || "selected",
    state: stateProp || "enabled",
  });

  return (
		<>
			{(state.state === "disabled" ||
				state.state === "enabled" ||
				state.state === "focused" ||
				state.state === "hovered" ||
				(state.state === "pressed" && state.type === "selected")) && (
				<div
					className={`w-7 flex flex-col items-center p-1 h-7 justify-center relative ${
						state.state === "disabled" ? "opacity-[0.38]" : ""
					} ${state.state === "pressed" ? "rounded-[100px]" : ""} ${typeSelectedStateClassName}`}
					onMouseLeave={() => {
						dispatch("mouse_leave");
					}}
					onMouseEnter={() => {
						dispatch("mouse_enter");
					}}
					onClick={() => {
						dispatch("click");
					}}
				>
					<div
						className={`w-7 flex mt-[-4.00px] items-center mr-[-4.00px] ml-[-4.00px] p-[11px] h-7 rounded-[100px] justify-center mb-[-4.00px] relative ${
							state.state === "pressed" ? "overflow-hidden" : ""
						} ${
							state.type === "selected" &&
							state.state === "hovered"
								? "bg-[#0076b414]"
								: state.state === "pressed" ||
									  (state.state === "focused" &&
											state.type === "selected")
									? "bg-[#0076b41f]"
									: state.type === "unselected" &&
										  state.state === "hovered"
										? "bg-[#00151d14]"
										: state.type === "unselected" &&
											  state.state === "focused"
											? "bg-[#005b8b1a]"
											: ""
						} ${stateLayerClassName}`}
					>
						{((state.state === "disabled" &&
							state.type === "selected") ||
							(state.state === "enabled" &&
								state.type === "selected") ||
							(state.state === "focused" &&
								state.type === "selected") ||
							(state.state === "hovered" &&
								state.type === "selected")) && (
							<div
								className={`w-[18px] mt-[-6.00px] mr-[-6.00px] ml-[-6.00px] h-[18px] rounded-sm mb-[-6.00px] relative ${
									state.state === "disabled"
										? "bg-neutral-600"
										: "bg-primary-700"
								} ${containerClassNameOverride}`}
							/>
						)}
						{state.state === "pressed" && (
							<div className="relative w-[18px] h-[18px] mt-[-6.00px] mb-[-6.00px] ml-[-6.00px] mr-[-6.00px] bg-primary-700 rounded-sm" />
						)}
						{state.type === "selected" && (
							<IconsCheckSmall5
								className={
									state.state === "pressed"
										? "!absolute !w-6 !h-6 !top-0.5 !left-0.5"
										: iconsCheckSmall5StyleOverrideClassName
								}
								color="#FCFCFC"
							/>
						)}

						{state.state === "pressed" && (
							// eslint-disable-next-line @next/next/no-img-element
							<img
								className="absolute w-7 h-6 top-1 left-0"
								alt="Ripple"
								src="https://c.animaapp.com/jUK70hSM/img/ripple.svg"
							/>
						)}
						{state.type === "unselected" && (
							<div
								className={`border-2 border-solid border-neutral-600 w-[18px] mt-[-6.00px] mr-[-6.00px] ml-[-6.00px] h-[18px] rounded-sm mb-[-6.00px] relative ${containerClassName}`}
							/>
						)}
					</div>
				</div>
			)}

			{state.type === "unselected" && state.state === "pressed" && (
				<TypeUnselectedStatePressed className="!absolute !w-7 !h-7 !top-0 !left-0" />
			)}
		</>
  );
};

function reducer(state: any, action: any) {
  if (state.state === "enabled" && state.type === "selected") {
    switch (action) {
      case "click":
        return {
          state: "enabled",
          type: "unselected",
        };
    }
  }

  if (state.state === "enabled" && state.type === "unselected") {
    switch (action) {
      case "click":
        return {
          state: "enabled",
          type: "selected",
        };
    }
  }

  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "hovered",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "enabled",
      };
  }

  return state;
}
