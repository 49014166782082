/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  type: "header" | "default";
  className: string;
  contentClassName?: string;
  textClassName: string;
  text: string | number;
}

export const Cell = ({ type, className, contentClassName, textClassName, text }: Props): JSX.Element => {
  return (
    <div
      className={`border-[#5b5b5b] w-[120px] flex border-t border-l flex-col items-start [border-top-style:solid] [border-left-style:solid] relative ${
        type === "default" ? "h-6" : ""
      } ${type === "header" ? "bg-[#ffffff0d]" : "bg-[#ffffff01]"} ${className}`}
    >
      <div
        className={`w-full flex self-stretch items-start flex-[0_0_auto] relative ${
          type === "header" ? "px-3 py-1" : "px-3 py-1"
        } ${type === "default" ? "mb-[-12.00px]" : ""} ${contentClassName}`}
      >
        <div
          className={`[font-family:'Inter',Helvetica] mt-[-1.00px] tracking-[0] text-xs flex-1 text-white leading-[15.6px] relative ${
            type === "header" ? "font-semibold" : "font-normal"
          } ${textClassName}`}
        >
          {text}
        </div>
      </div>
    </div>
  );
};
