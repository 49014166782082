/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";
import { Cell } from "../Cell";

interface Props {
  className: any;
  cellText: string;
  cellTextClassName: any;
  cellTypeDefaultClassName: any;
  cellContentClassName: any;
  cellTypeDefaultClassNameOverride: any;
  cellTextClassNameOverride: any;
  cellText1: string;
  cellContentClassNameOverride: any;
  cellTypeHeaderClassName: any;
  cellDivClassName: any;
  cellText2: string;
  cellText3: string;
  cellDivClassNameOverride: any;
  cellTypeHeaderClassNameOverride: any;
  cellText4: string;
  cellTextClassName1: any;
  cellContentWrapperClassName: any;
  cellText5: string;
  cellTextClassName2: any;
  cellContentWrapperClassNameOverride: any;
}

export const Row = ({
  className,
  cellText,
  cellTextClassName,
  cellTypeDefaultClassName,
  cellContentClassName,
  cellTypeDefaultClassNameOverride,
  cellTextClassNameOverride,
  cellText1,
  cellContentClassNameOverride,
  cellTypeHeaderClassName,
  cellDivClassName,
  cellText2,
  cellText3,
  cellDivClassNameOverride,
  cellTypeHeaderClassNameOverride,
  cellText4,
  cellTextClassName1,
  cellContentWrapperClassName,
  cellText5,
  cellTextClassName2,
  cellContentWrapperClassNameOverride,
}: Props): JSX.Element => {
  return (
    <div className={`flex items-start relative w-[496px] bg-[#ffffff00] overflow-hidden ${className}`}>
      <Cell className={cellTypeDefaultClassName} text={cellText} textClassName={cellTextClassName} type="header" />
      <Cell
        className={cellTypeDefaultClassNameOverride}
        contentClassName={cellContentClassName}
        text={cellText1}
        textClassName={cellTextClassNameOverride}
        type="header"
      />
      <Cell
        className={cellTypeHeaderClassName}
        contentClassName={cellContentClassNameOverride}
        text={cellText2}
        textClassName={cellDivClassName}
        type="header"
      />
      <Cell
        className={cellTypeHeaderClassNameOverride}
        text={cellText3}
        textClassName={cellDivClassNameOverride}
        type="header"
      />
      <Cell className={cellContentWrapperClassName} text={cellText4} textClassName={cellTextClassName1} type="header" />
      <Cell
        className={cellContentWrapperClassNameOverride}
        text={cellText5}
        textClassName={cellTextClassName2}
        type="header"
      />
    </div>
  );
};
