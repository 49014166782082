/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  className: any;
}

export const TypeUnselectedStatePressed = ({ className }: Props): JSX.Element => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="28"
      viewBox="0 0 28 28"
      width="28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_318_35042)">
        <rect fill="#005B8B" fillOpacity="0.1" height="28" rx="14" width="28" />
        <rect height="16" rx="1" stroke="#666973" strokeWidth="2" width="16" x="6" y="6" />
        <path
          d="M28 6.2334V25.3513C28 26.8142 26.8393 28 25.4074 28H-7C-7 14.469 3.73687 3.5 16.9815 3.5C20.9539 3.5 24.7008 4.48674 28 6.2334Z"
          fill="#0076B4"
          opacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_318_35042">
          <rect fill="white" height="28" rx="14" width="28" />
        </clipPath>
      </defs>
    </svg>
  );
};
