/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import React from "react";

interface Props {
  color: string;
  className: any;
}

export const IconsCheckSmall5 = ({ color = "black", className }: Props): JSX.Element => {
  return (
    <svg
      className={`${className}`}
      fill="none"
      height="8"
      viewBox="0 0 9 8"
      width="9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.85798 5.41401L2.57227 4.12829L3.02227 3.67829L3.85798 4.51401L5.97941 2.39258L6.42941 2.84258L3.85798 5.41401Z"
        fill={color}
      />
    </svg>
  );
};
